import { apiGetData } from '@/resource/register'

export default {
  data() {
    return {
      form: {
        countryId: null,
        streetNumber: '',
        address: '',
        state: '',
        suburb: '',
        postcode: '',
        usCitizen: null,
        step: 2,
      },
      rules: {
        countryId: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.countryReq'),
          },
        ],
        streetNumber: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.numberReq'),
          },
        ],
        address: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.addressReq'),
          },
        ],
        state: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.stateReq'),
          },
        ],
        suburb: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.suburbReq'),
          },
        ],
        postcode: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.postcodeReq'),
          },
        ],
        usCitizen: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.answerReq'),
          },
        ],
      },
      diffFieldCountryList: [6907, 4101],
      provinces: [],
    }
  },
  mounted() {
    this.getUserDate()
  },
  watch: {
    'form.countryId'() {
      if (this.allProvinces.length > 0 && this.form.countryId == 3512) {
        //au
        this.getProvinces()
        this.clearState()
      }

      if (!this.showDiffField && this.form.streetNumber) {
        this.form.streetNumber = null
      }
    },
  },
  computed: {
    countries() {
      return this.$store.state.register.countries
    },
    allProvinces() {
      return this.$store.state.register.provinces
    },
    disableField() {
      return this.$store.state.register.disableField
    },
    showDiffField() {
      return this.diffFieldCountryList.includes(parseInt(this.form.countryId))
    },
  },
  methods: {
    getProvinces() {
      this.provinces = this.allProvinces.filter(province => province.countryID == this.form.countryId)
    },
    clearState() {
      if (!this.disableField) {
        const state = this.provinces.find(province => province.provinceNameEn == this.form.state)
        if (!state) {
          this.form.state = ''
        }
      }
    },
    getUserDate() {
      apiGetData(2).then(resp => {
        this.form = { ...resp.data.data }
      })
    },
  },
}
