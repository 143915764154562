<template>
  <el-form :model="form" :rules="rules" ref="ruleForm">
    <el-row :gutter="60">
      <el-col :xs="24" :sm="24" :md="12">
        <SelectForm
          v-model="form.countryId"
          :disabled="true"
          :label="$t('register.personalDetails.page2.mainResidency')"
          name="countryCode"
        >
          <el-option
            v-for="(item, index) in countries"
            :key="index"
            :label="item.countryName"
            :value="item.id"
            :data-testid="item.id"
          ></el-option>
        </SelectForm>
      </el-col>
    </el-row>
    <el-row :gutter="60">
      <el-col :xs="24" :sm="24" :md="12">
        <el-row v-if="showDiffField" :gutter="30" class="date_birth">
          <el-col :xs="24" :sm="12">
            <InputForm
              :label="$t('register.personalDetails.page2.streetNumber')"
              name="streetNumber"
              maxlength="20"
              v-model="form.streetNumber"
              :disabled="disableField"
            ></InputForm>
          </el-col>
          <el-col :xs="24" :sm="12">
            <InputForm
              :label="$t('register.personalDetails.page2.streetName')"
              name="address"
              maxlength="256"
              v-model="form.address"
              :disabled="disableField"
            ></InputForm>
          </el-col>
        </el-row>
        <InputForm
          v-else
          :label="$t('register.personalDetails.page2.address')"
          name="address"
          maxlength="256"
          v-model="form.address"
          :disabled="disableField"
        ></InputForm>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12">
        <SelectForm
          v-if="form.countryId == 3512"
          v-model="form.state"
          :disabled="disableField"
          :label="$t('register.personalDetails.page2.provinceOrState')"
          name="state"
        >
          <el-option
            v-for="(item, index) in provinces"
            :key="index"
            :label="item.provinceName"
            :value="item.provinceNameEn"
            :data-testid="'province0' + (index + 1)"
          ></el-option>
        </SelectForm>
        <InputForm
          v-else
          :label="$t('register.personalDetails.page2.provinceOrState')"
          name="state"
          maxlength="128"
          v-model="form.state"
          :disabled="disableField"
        ></InputForm>
      </el-col>
    </el-row>
    <el-row :gutter="60">
      <el-col :xs="24" :sm="24" :md="12">
        <InputForm
          :label="$t('register.personalDetails.page2.cityOrSuburb')"
          name="suburb"
          maxlength="128"
          v-model="form.suburb"
          :disabled="disableField"
        ></InputForm>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12">
        <InputForm
          :label="$t('register.personalDetails.page2.postcode')"
          name="postcode"
          maxlength="64"
          v-model="form.postcode"
          :disabled="disableField"
        ></InputForm>
      </el-col>
    </el-row>
    <el-row :gutter="60">
      <el-col :xs="24" :sm="24" :md="12">
        <el-form-item class="vRadio" :label="$t('register.personalDetails.page2.usQuestion')" prop="usCitizen">
          <el-radio :disabled="disableField" v-model="form.usCitizen" :label="true" data-testid="yes">
            {{ this.$t('common.field.yes') }}
          </el-radio>
          <el-radio :disabled="disableField" v-model="form.usCitizen" :label="false" data-testid="no">
            {{ this.$t('common.field.no') }}
          </el-radio>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import userAddress from '@/mixins/register/userAddress';

export default {
  mixins: [userAddress]
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/personalDetails.scss';
</style>
